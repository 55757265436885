<h2 mat-dialog-title>Neue Version anlegen</h2>
<mat-dialog-content>
    <p>Geben Sie die Projektnummer für die neue Version ein</p>

    <mat-form-field appearance="fill">
        <mat-label>Projektnummer</mat-label>
        <input matInput [(ngModel)]="data.projectNumber" />
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-raised-button color="warn" (click)="onNoClick()">Abbrechen</button>
    <button mat-raised-button color="primary" [mat-dialog-close]="data" cdkFocusInitial>Anlegen</button>
</mat-dialog-actions>