import { Injector, Inject } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActionType, DialogMessageComponent, DialogMessageData } from "src/app/components/dialogs/dialog-message/dialog-message.component";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ServerService } from "src/app/services/server.service";
import { DialogYesNoComponent, DialogYesNoData } from "src/app/components/dialogs/dialog-yes-no/dialog-yes-no.component";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DocumentService } from "src/app/services/document.service";
import { UserService } from "src/app/services/user.service";
import { User } from "src/app/models/user.model";
import { DialogDataNewVersion, DialogNewVersionComponent } from "src/app/components/dialogs/dialog-new-version/dialog-new-version.component";


export class W3codeBase {
    protected dialog: MatDialog
    protected translate: TranslateService
    protected router: Router
    protected activeRoute: ActivatedRoute
    protected server: ServerService
    protected documentService: DocumentService
    protected snackbar: MatSnackBar
    protected userService: UserService

    constructor(@Inject(Injector) injector: Injector) {
        this.dialog = injector.get(MatDialog)
        this.translate = injector.get(TranslateService)
        this.router = injector.get(Router)
        this.server = injector.get(ServerService)
        this.documentService = injector.get(DocumentService)
        this.activeRoute = injector.get(ActivatedRoute)
        this.snackbar = injector.get(MatSnackBar)
        this.userService = injector.get(UserService)
    }

    openNewVersionMessage() {
        return new Promise<DialogDataNewVersion | undefined>((resolve) => {
            const dialogRef = this.dialog.open<DialogNewVersionComponent, any, DialogDataNewVersion>(DialogNewVersionComponent, {
                data: { projectNumber: "" } as DialogDataNewVersion
            })

            dialogRef.afterClosed().subscribe(data => {
                resolve(data)
            })
        })
    }

    openErrorMessage(message: string, para?: string) {
        return this.openMessageDialog({
            message: message,
            title: "title.error"
        }, {
            error: para ? para : ""
        })
    }
    openSuccessMessage(message: string) {
        return this.openMessageDialog({
            message: message,
            title: "title.success"
        })
    }
    openQuestionMessage(message: string) {
        return this.openMessageDialog({
            message: message,
            title: "title.question",
            actions: [{
                color: "primary",
                type: ActionType.Yes
            }, {
                color: "primary",
                type: ActionType.No
            }]
        })
    }

    openSuccessInfo(message: string) {
        this.snackbar.open(this.translate.instant(message), "OK", {
            duration: 2000
        })
    }

    private openMessageDialog(data: DialogMessageData, paras?: object) {
        data.message = this.translate.instant(data.message, paras)
        data.title = this.translate.instant(data.title)

        return this.dialog.open<DialogMessageComponent, DialogMessageData, ActionType>(DialogMessageComponent, {
            data: data
        })
    }

    getMailSaluation(user: User) {
        let saluation = ""
        if (user.salutation == "Herr") {
            saluation = this.translate.instant("mail.mSaluation")
        } else if (user.salutation == "Frau") {
            saluation = this.translate.instant("mail.wSaluation")
        } else {
            saluation = this.translate.instant("mail.dSaluation")
        }

        return saluation + " " + user.firstname + " " + user.lastname
    }


    onClickOpenFile(filename: string) {
        const a = document.createElement("a")
        a.href = "./assets/" + filename
        a.target = "_blank"

        a.click()
    }
}
