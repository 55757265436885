import { Injectable } from '@angular/core';


export enum CodeType {
    ElectroNormal = "EN",
    DampfNormal = "DN",
    GasNormal = "GN"
}

export enum Language {
    DE = "de",
    EN = "en",
    FR = "fr"
}

export interface CodeData {
    code: number
    sensibel: number
    latent: number
}

export interface CodedeviceData extends CodeData {
    type: CodeType
    text: string
    dampf: number
}

export interface CodeSinkData extends CodeData {
    abluftstutzen: boolean
    wgr: boolean
    maschinentyp: SinkType
    normtellerleistung: number
    sensibelLatent: number
    warmeabgabe: number
    zuluftstrom: number
    abluftstromAnteilig: number
    abluftstromErforderlich: number

}

export interface CodeText {
    code: number
    de: string
    en: string
    fr: string
}

export enum SinkType {
    k = "Korbspülmaschine",
    b = "Bandspülmaschine",
    none = ""
}

@Injectable({
    providedIn: 'root'
})
export class CodeService {
    datadevice: CodedeviceData[] = []
    dataSink: CodeSinkData[] = []

    codeText: CodeText[] = []



    getCodes(type: CodeType, language = Language.DE) {
        return this.datadevice.filter(val => val.type == type).map((value) => {

            const text = this.codeText.find(val => val.code == value.code)
            value.text = (!text) ? "" : text[language]

            return value
        })
    }

    getSinkCodes() {
        return this.dataSink
    }

    getCodeData(code: number | undefined, type: CodeType) {
        const foundCode = this.datadevice.filter(codeData => codeData.code == code && codeData.type == type)

        if (!foundCode) return undefined

        return foundCode[0]
    }

    getSinkCodeData(code: number | undefined) {
        const foundCode = this.dataSink.filter(codeData => codeData.code == code)

        if (!foundCode) return undefined

        return foundCode[0]
    }





    constructor() {
        this.datadevice = [
            {
                "code": 11,
                "sensibel": 35,
                "dampf": 294,
                "latent": 200,
                "type": CodeType.ElectroNormal,
                "text": ""
            },
            {
                "code": 11,
                "sensibel": 35,
                "dampf": 294,
                "latent": 200,
                "type": CodeType.DampfNormal,
                "text": ""
            },
            {
                "code": 11,
                "sensibel": 100,
                "dampf": 441,
                "latent": 300,
                "type": CodeType.GasNormal,
                "text": ""
            },
            {
                "code": 12,
                "sensibel": 40,
                "dampf": 15,
                "latent": 10,
                "type": CodeType.ElectroNormal,
                "text": ""
            },
            {
                "code": 12,
                "sensibel": 40,
                "dampf": 15,
                "latent": 10,
                "type": CodeType.DampfNormal,
                "text": ""
            },
            {
                "code": 13,
                "sensibel": 25,
                "dampf": 294,
                "latent": 200,
                "type": CodeType.ElectroNormal,
                "text": ""
            },
            {
                "code": 13,
                "sensibel": 25,
                "dampf": 294,
                "latent": 200,
                "type": CodeType.DampfNormal,
                "text": ""
            },
            {
                "code": 14,
                "sensibel": 25,
                "dampf": 294,
                "latent": 200,
                "type": CodeType.ElectroNormal,
                "text": ""
            },
            {
                "code": 14,
                "sensibel": 25,
                "dampf": 294,
                "latent": 200,
                "type": CodeType.DampfNormal,
                "text": ""
            },
            {
                "code": 15,
                "sensibel": 120,
                "dampf": 265,
                "latent": 180,
                "type": CodeType.ElectroNormal,
                "text": ""
            },
            {
                "code": 15,
                "sensibel": 120,
                "dampf": 265,
                "latent": 180,
                "type": CodeType.DampfNormal,
                "text": ""
            },
            {
                "code": 15,
                "sensibel": 150,
                "dampf": 265,
                "latent": 180,
                "type": CodeType.GasNormal,
                "text": ""
            },
            {
                "code": 21,
                "sensibel": 450,
                "dampf": 588,
                "latent": 400,
                "type": CodeType.ElectroNormal,
                "text": ""
            },
            {
                "code": 21,
                "sensibel": 450,
                "dampf": 588,
                "latent": 400,
                "type": CodeType.DampfNormal,
                "text": ""
            },
            {
                "code": 21,
                "sensibel": 450,
                "dampf": 630,
                "latent": 450,
                "type": CodeType.GasNormal,
                "text": ""
            },
            {
                "code": 22,
                "sensibel": 330,
                "dampf": 588,
                "latent": 400,
                "type": CodeType.ElectroNormal,
                "text": ""
            },
            {
                "code": 22,
                "sensibel": 330,
                "dampf": 588,
                "latent": 400,
                "type": CodeType.DampfNormal,
                "text": ""
            },
            {
                "code": 22,
                "sensibel": 350,
                "dampf": 588,
                "latent": 400,
                "type": CodeType.GasNormal,
                "text": ""
            },
            {
                "code": 23,
                "sensibel": 800,
                "dampf": 257,
                "latent": 175,
                "type": CodeType.ElectroNormal,
                "text": ""
            },
            {
                "code": 23,
                "sensibel": 800,
                "dampf": 257,
                "latent": 175,
                "type": CodeType.DampfNormal,
                "text": ""
            },
            {
                "code": 23,
                "sensibel": 720,
                "dampf": 294,
                "latent": 200,
                "type": CodeType.GasNormal,
                "text": ""
            },
            {
                "code": 24,
                "sensibel": 350,
                "dampf": 235,
                "latent": 160,
                "type": CodeType.ElectroNormal,
                "text": ""
            },
            {
                "code": 24,
                "sensibel": 350,
                "dampf": 235,
                "latent": 160,
                "type": CodeType.DampfNormal,
                "text": ""
            },
            {
                "code": 24,
                "sensibel": 350,
                "dampf": 294,
                "latent": 200,
                "type": CodeType.GasNormal,
                "text": ""
            },
            {
                "code": 25,
                "sensibel": 70,
                "dampf": 220,
                "latent": 150,
                "type": CodeType.ElectroNormal,
                "text": ""
            },
            {
                "code": 25,
                "sensibel": 70,
                "dampf": 220,
                "latent": 150,
                "type": CodeType.DampfNormal,
                "text": ""
            },
            {
                "code": 25,
                "sensibel": 100,
                "dampf": 220,
                "latent": 150,
                "type": CodeType.GasNormal,
                "text": ""
            },
            {
                "code": 26,
                "sensibel": 250,
                "dampf": 338,
                "latent": 230,
                "type": CodeType.ElectroNormal,
                "text": ""
            },
            {
                "code": 26,
                "sensibel": 250,
                "dampf": 338,
                "latent": 230,
                "type": CodeType.DampfNormal,
                "text": ""
            },
            {
                "code": 27,
                "sensibel": 150,
                "dampf": 235,
                "latent": 160,
                "type": CodeType.ElectroNormal,
                "text": ""
            },
            {
                "code": 27,
                "sensibel": 150,
                "dampf": 235,
                "latent": 160,
                "type": CodeType.DampfNormal,
                "text": ""
            },
            {
                "code": 28,
                "sensibel": 90,
                "dampf": 1030,
                "latent": 700,
                "type": CodeType.ElectroNormal,
                "text": ""
            },
            {
                "code": 28,
                "sensibel": 90,
                "dampf": 1030,
                "latent": 700,
                "type": CodeType.DampfNormal,
                "text": ""
            },
            {
                "code": 28,
                "sensibel": 90,
                "dampf": 1030,
                "latent": 700,
                "type": CodeType.GasNormal,
                "text": ""
            },
            {
                "code": 291,
                "sensibel": 50,
                "dampf": 147,
                "latent": 100,
                "type": CodeType.ElectroNormal,
                "text": ""
            },
            {
                "code": 291,
                "sensibel": 50,
                "dampf": 147,
                "latent": 100,
                "type": CodeType.DampfNormal,
                "text": ""
            },
            {
                "code": 292,
                "sensibel": 50,
                "dampf": 808,
                "latent": 550,
                "type": CodeType.ElectroNormal,
                "text": ""
            },
            {
                "code": 292,
                "sensibel": 50,
                "dampf": 808,
                "latent": 550,
                "type": CodeType.DampfNormal,
                "text": ""
            },
            {
                "code": 210,
                "sensibel": 0,
                "dampf": 0,
                "latent": 0,
                "type": CodeType.ElectroNormal,
                "text": ""
            },
            {
                "code": 210,
                "sensibel": 0,
                "dampf": 0,
                "latent": 0,
                "type": CodeType.DampfNormal,
                "text": ""
            },
            {
                "code": 211,
                "sensibel": 0,
                "dampf": 0,
                "latent": 0,
                "type": CodeType.ElectroNormal,
                "text": ""
            },
            {
                "code": 211,
                "sensibel": 0,
                "dampf": 0,
                "latent": 0,
                "type": CodeType.DampfNormal,
                "text": ""
            },
            {
                "code": 211,
                "sensibel": 0,
                "dampf": 0,
                "latent": 0,
                "type": CodeType.GasNormal,
                "text": ""
            },
            {
                "code": 212,
                "sensibel": 70,
                "dampf": 41,
                "latent": 28,
                "type": CodeType.ElectroNormal,
                "text": ""
            },
            {
                "code": 212,
                "sensibel": 70,
                "dampf": 41,
                "latent": 28,
                "type": CodeType.DampfNormal,
                "text": ""
            },
            {
                "code": 212,
                "sensibel": 450,
                "dampf": 630,
                "latent": 450,
                "type": CodeType.GasNormal,
                "text": ""
            },
            {
                "code": 213,
                "sensibel": 0,
                "dampf": 0,
                "latent": 0,
                "type": CodeType.ElectroNormal,
                "text": ""
            },
            {
                "code": 213,
                "sensibel": 0,
                "dampf": 0,
                "latent": 0,
                "type": CodeType.DampfNormal,
                "text": ""
            },
            {
                "code": 213,
                "sensibel": 0,
                "dampf": 0,
                "latent": 0,
                "type": CodeType.GasNormal,
                "text": ""
            },
            {
                "code": 215,
                "sensibel": 450,
                "dampf": 588,
                "latent": 400,
                "type": CodeType.ElectroNormal,
                "text": ""
            },
            {
                "code": 215,
                "sensibel": 450,
                "dampf": 588,
                "latent": 400,
                "type": CodeType.DampfNormal,
                "text": ""
            },
            {
                "code": 216,
                "sensibel": 90,
                "dampf": 1030,
                "latent": 700,
                "type": CodeType.ElectroNormal,
                "text": ""
            },
            {
                "code": 216,
                "sensibel": 90,
                "dampf": 1030,
                "latent": 700,
                "type": CodeType.DampfNormal,
                "text": ""
            },
            {
                "code": 217,
                "sensibel": 200,
                "dampf": 118,
                "latent": 80,
                "type": CodeType.ElectroNormal,
                "text": ""
            },
            {
                "code": 217,
                "sensibel": 200,
                "dampf": 118,
                "latent": 80,
                "type": CodeType.DampfNormal,
                "text": ""
            },
            {
                "code": 218,
                "sensibel": 35,
                "dampf": 294,
                "latent": 200,
                "type": CodeType.ElectroNormal,
                "text": ""
            },
            {
                "code": 218,
                "sensibel": 35,
                "dampf": 294,
                "latent": 200,
                "type": CodeType.DampfNormal,
                "text": ""
            },
            {
                "code": 219,
                "sensibel": 40,
                "dampf": 15,
                "latent": 10,
                "type": CodeType.ElectroNormal,
                "text": ""
            },
            {
                "code": 219,
                "sensibel": 40,
                "dampf": 15,
                "latent": 10,
                "type": CodeType.DampfNormal,
                "text": ""
            },
            {
                "code": 411,
                "sensibel": 200,
                "dampf": 118,
                "latent": 80,
                "type": CodeType.ElectroNormal,
                "text": ""
            },
            {
                "code": 411,
                "sensibel": 200,
                "dampf": 118,
                "latent": 80,
                "type": CodeType.DampfNormal,
                "text": ""
            },
            {
                "code": 412,
                "sensibel": 200,
                "dampf": 118,
                "latent": 80,
                "type": CodeType.ElectroNormal,
                "text": ""
            },
            {
                "code": 412,
                "sensibel": 200,
                "dampf": 118,
                "latent": 80,
                "type": CodeType.DampfNormal,
                "text": ""
            },
            {
                "code": 413,
                "sensibel": 120,
                "dampf": 71,
                "latent": 48,
                "type": CodeType.ElectroNormal,
                "text": ""
            },
            {
                "code": 413,
                "sensibel": 120,
                "dampf": 71,
                "latent": 48,
                "type": CodeType.DampfNormal,
                "text": ""
            },
            {
                "code": 414,
                "sensibel": 260,
                "dampf": 154,
                "latent": 104,
                "type": CodeType.ElectroNormal,
                "text": ""
            },
            {
                "code": 414,
                "sensibel": 260,
                "dampf": 154,
                "latent": 104,
                "type": CodeType.DampfNormal,
                "text": ""
            },
            {
                "code": 415,
                "sensibel": 250,
                "dampf": 147,
                "latent": 100,
                "type": CodeType.GasNormal,
                "text": ""
            },
            {
                "code": 416,
                "sensibel": 200,
                "dampf": 118,
                "latent": 80,
                "type": CodeType.GasNormal,
                "text": ""
            },
            {
                "code": 417,
                "sensibel": 300,
                "dampf": 177,
                "latent": 120,
                "type": CodeType.GasNormal,
                "text": ""
            },
            {
                "code": 42,
                "sensibel": 200,
                "dampf": 220,
                "latent": 150,
                "type": CodeType.ElectroNormal,
                "text": ""
            },
            {
                "code": 42,
                "sensibel": 200,
                "dampf": 220,
                "latent": 150,
                "type": CodeType.DampfNormal,
                "text": ""
            },
            {
                "code": 42,
                "sensibel": 250,
                "dampf": 265,
                "latent": 150,
                "type": CodeType.GasNormal,
                "text": ""
            },
            {
                "code": 43,
                "sensibel": 50,
                "dampf": 15,
                "latent": 10,
                "type": CodeType.ElectroNormal,
                "text": ""
            },
            {
                "code": 43,
                "sensibel": 50,
                "dampf": 15,
                "latent": 10,
                "type": CodeType.DampfNormal,
                "text": ""
            },
            {
                "code": 44,
                "sensibel": 125,
                "dampf": 294,
                "latent": 200,
                "type": CodeType.ElectroNormal,
                "text": ""
            },
            {
                "code": 44,
                "sensibel": 125,
                "dampf": 294,
                "latent": 200,
                "type": CodeType.DampfNormal,
                "text": ""
            },
            {
                "code": 44,
                "sensibel": 195,
                "dampf": 323,
                "latent": 220,
                "type": CodeType.GasNormal,
                "text": ""
            },
            {
                "code": 45,
                "sensibel": 350,
                "dampf": 0,
                "latent": 0,
                "type": CodeType.ElectroNormal,
                "text": ""
            },
            {
                "code": 45,
                "sensibel": 350,
                "dampf": 0,
                "latent": 0,
                "type": CodeType.DampfNormal,
                "text": ""
            },
            {
                "code": 45,
                "sensibel": 350,
                "dampf": 0,
                "latent": 0,
                "type": CodeType.GasNormal,
                "text": ""
            },
            {
                "code": 46,
                "sensibel": 700,
                "dampf": 0,
                "latent": 0,
                "type": CodeType.ElectroNormal,
                "text": ""
            },
            {
                "code": 46,
                "sensibel": 700,
                "dampf": 0,
                "latent": 0,
                "type": CodeType.DampfNormal,
                "text": ""
            },
            {
                "code": 47,
                "sensibel": 175,
                "dampf": 0,
                "latent": 0,
                "type": CodeType.ElectroNormal,
                "text": ""
            },
            {
                "code": 47,
                "sensibel": 175,
                "dampf": 0,
                "latent": 0,
                "type": CodeType.DampfNormal,
                "text": ""
            },
            {
                "code": 48,
                "sensibel": 1000,
                "dampf": 0,
                "latent": 0,
                "type": CodeType.ElectroNormal,
                "text": ""
            },
            {
                "code": 48,
                "sensibel": 1000,
                "dampf": 0,
                "latent": 0,
                "type": CodeType.DampfNormal,
                "text": ""
            },
            {
                "code": 51,
                "sensibel": 125,
                "dampf": 0,
                "latent": 200,
                "type": CodeType.ElectroNormal,
                "text": ""
            },
            {
                "code": 51,
                "sensibel": 125,
                "dampf": 0,
                "latent": 200,
                "type": CodeType.DampfNormal,
                "text": ""
            },
            {
                "code": 52,
                "sensibel": 700,
                "dampf": 0,
                "latent": 0,
                "type": CodeType.ElectroNormal,
                "text": ""
            },
            {
                "code": 52,
                "sensibel": 700,
                "dampf": 0,
                "latent": 0,
                "type": CodeType.DampfNormal,
                "text": ""
            },
            {
                "code": 53,
                "sensibel": 300,
                "dampf": 0,
                "latent": 0,
                "type": CodeType.ElectroNormal,
                "text": ""
            },
            {
                "code": 53,
                "sensibel": 300,
                "dampf": 0,
                "latent": 0,
                "type": CodeType.DampfNormal,
                "text": ""
            },
            {
                "code": 54,
                "sensibel": 100,
                "dampf": 0,
                "latent": 200,
                "type": CodeType.ElectroNormal,
                "text": ""
            },
            {
                "code": 54,
                "sensibel": 100,
                "dampf": 0,
                "latent": 200,
                "type": CodeType.DampfNormal,
                "text": ""
            }
        ]


        this.codeText = [
            {
                "code": 11,
                "de": "Kochkessel, Kochautomat, Nudelkocher",
                "en": "",
                "fr": ""
            },
            {
                "code": 12,
                "de": "Druckkochkessel",
                "en": "",
                "fr": ""
            },
            {
                "code": 13,
                "de": "Hochdruckdämpfer, Einschubgerät",
                "en": "",
                "fr": ""
            },
            {
                "code": 14,
                "de": "Hochdruckdämpfer, Durchschubgerät",
                "en": "",
                "fr": ""
            },
            {
                "code": 15,
                "de": "Heißluftdämpfer",
                "en": "",
                "fr": ""
            },
            {
                "code": 21,
                "de": "Kippbratpfanne, Druckgarpfanne, Braisiere, Gyrosgrill, Griddlepfanne, Druckbraisiere, Variobrätter, Thermojet, Vario Cocking Center",
                "en": "",
                "fr": ""
            },
            {
                "code": 22,
                "de": "Brat-, Grill- und Griddleplatte, Gyrosgrill",
                "en": "",
                "fr": ""
            },
            {
                "code": 23,
                "de": "Grill- und Salamandergeräte, Hähnchengrill, Kontaktgrill",
                "en": "",
                "fr": ""
            },
            {
                "code": 24,
                "de": "Brat- und Backöfen",
                "en": "",
                "fr": ""
            },
            {
                "code": 25,
                "de": "Heißluftgerät, Auftaugerät",
                "en": "",
                "fr": ""
            },
            {
                "code": 26,
                "de": "Brat- u. Grillautomat für Kurzbratstücke",
                "en": "",
                "fr": ""
            },
            {
                "code": 27,
                "de": "Soßenautomat",
                "en": "",
                "fr": ""
            },
            {
                "code": 28,
                "de": "Friteuse",
                "en": "",
                "fr": ""
            },
            {
                "code": 291,
                "de": "Fritierautomat mit integrierter Absaugung",
                "en": "",
                "fr": ""
            },
            {
                "code": 292,
                "de": "Fritierautomat ohne Absaugung",
                "en": "",
                "fr": ""
            },
            {
                "code": 210,
                "de": "Siehe Gerät Nr. 413",
                "en": "",
                "fr": ""
            },
            {
                "code": 211,
                "de": "Siehe Gerät Nr. 412",
                "en": "",
                "fr": ""
            },
            {
                "code": 212,
                "de": "Wok",
                "en": "",
                "fr": ""
            },
            {
                "code": 213,
                "de": "Siehe Gerät Nr. 414",
                "en": "",
                "fr": ""
            },
            {
                "code": 214,
                "de": "Multifunktionsgerät",
                "en": "",
                "fr": ""
            },
            {
                "code": 215,
                "de": "Multifunktionsgerät - Kippbratpfanne",
                "en": "",
                "fr": ""
            },
            {
                "code": 216,
                "de": "Multifunktionsgerät - frittierfunktion",
                "en": "",
                "fr": ""
            },
            {
                "code": 217,
                "de": "Multifunktionsgerät - Herd",
                "en": "",
                "fr": ""
            },
            {
                "code": 218,
                "de": "Multifunktionsgerät - Kochkessel und Kochautomat",
                "en": "",
                "fr": ""
            },
            {
                "code": 219,
                "de": "Multifunktionsgerät - Druckgaren",
                "en": "",
                "fr": ""
            },
            {
                "code": 411,
                "de": "Elektro-Herd, Kochstelle (Massekochplatte f=1)",
                "en": "",
                "fr": ""
            },
            {
                "code": 412,
                "de": "Elektro-Herd, Kochstelle (Keramikkochplatte f=1)",
                "en": "",
                "fr": ""
            },
            {
                "code": 413,
                "de": "Elektro-Herd, Kochstelle (Induktionskochstelle f=0,6)",
                "en": "",
                "fr": ""
            },
            {
                "code": 414,
                "de": "Elektro-Herd, Kochstelle (Großfeld-Stahlplatte f=1,3)",
                "en": "",
                "fr": ""
            },
            {
                "code": 415,
                "de": "Gas-Herd, Kochstelle (offene Kochstelle f=1)",
                "en": "",
                "fr": ""
            },
            {
                "code": 416,
                "de": "Gas-Herd, Kochstelle (Keramikkochplatte f=0,8)",
                "en": "",
                "fr": ""
            },
            {
                "code": 417,
                "de": "Gas-Herd, Kochstelle (Glühplatte f=1,2)",
                "en": "",
                "fr": ""
            },
            {
                "code": 42,
                "de": "Hockerkocher",
                "en": "",
                "fr": ""
            },
            {
                "code": 43,
                "de": "Mikrowellengerät",
                "en": "",
                "fr": ""
            },
            {
                "code": 44,
                "de": "Wasserbad; Chaving Disk",
                "en": "",
                "fr": ""
            },
            {
                "code": 45,
                "de": "Wärmeanrichte und Wärmeschrank",
                "en": "",
                "fr": ""
            },
            {
                "code": 46,
                "de": "Kühlschränk (ohne zentrale Kälteversorgung)",
                "en": "",
                "fr": ""
            },
            {
                "code": 47,
                "de": "Küchenmaschine",
                "en": "",
                "fr": ""
            },
            {
                "code": 48,
                "de": "Fördereinrichtunge",
                "en": "",
                "fr": ""
            },
            {
                "code": 51,
                "de": "Warmausgabegerät, Wärmelampe",
                "en": "",
                "fr": ""
            },
            {
                "code": 52,
                "de": "Kaltausgabegerät (ohne zentrale Kälteversorgung)",
                "en": "",
                "fr": ""
            },
            {
                "code": 53,
                "de": "Geschirrspender",
                "en": "",
                "fr": ""
            },
            {
                "code": 54,
                "de": "Getränkebrühanlage",
                "en": "",
                "fr": ""
            }
        ]


        this.dataSink = [
            {
                "code": 1,
                "abluftstutzen": false,
                "wgr": false,
                "maschinentyp": SinkType.k,
                "normtellerleistung": 250,
                "sensibel": 0.2,
                "latent": 0.1,
                "sensibelLatent": 0.6,
                "warmeabgabe": 0.8,
                "zuluftstrom": 300,
                "abluftstromAnteilig": 0,
                "abluftstromErforderlich": 320
            },
            {
                "code": 2,
                "abluftstutzen": false,
                "wgr": false,
                "maschinentyp": SinkType.k,
                "normtellerleistung": 400,
                "sensibel": 0.6,
                "latent": 0.2,
                "sensibelLatent": 1,
                "warmeabgabe": 1.6,
                "zuluftstrom": 600,
                "abluftstromAnteilig": 0,
                "abluftstromErforderlich": 630
            },
            {
                "code": 3,
                "abluftstutzen": false,
                "wgr": false,
                "maschinentyp": SinkType.k,
                "normtellerleistung": 600,
                "sensibel": 1.2,
                "latent": 0.3,
                "sensibelLatent": 1.5,
                "warmeabgabe": 2.7,
                "zuluftstrom": 1010,
                "abluftstromAnteilig": 0,
                "abluftstromErforderlich": 1070
            },
            {
                "code": 4,
                "abluftstutzen": false,
                "wgr": false,
                "maschinentyp": SinkType.k,
                "normtellerleistung": 800,
                "sensibel": 1.7,
                "latent": 0.4,
                "sensibelLatent": 1.9,
                "warmeabgabe": 3.6,
                "zuluftstrom": 1350,
                "abluftstromAnteilig": 0,
                "abluftstromErforderlich": 1420
            },
            {
                "code": 5,
                "abluftstutzen": false,
                "wgr": false,
                "maschinentyp": SinkType.k,
                "normtellerleistung": 1000,
                "sensibel": 2.1,
                "latent": 0.5,
                "sensibelLatent": 2.4,
                "warmeabgabe": 4.5,
                "zuluftstrom": 1680,
                "abluftstromAnteilig": 0,
                "abluftstromErforderlich": 1770
            },
            {
                "code": 6,
                "abluftstutzen": false,
                "wgr": false,
                "maschinentyp": SinkType.k,
                "normtellerleistung": 1200,
                "sensibel": 2.4,
                "latent": 0.6,
                "sensibelLatent": 3,
                "warmeabgabe": 5.4,
                "zuluftstrom": 2020,
                "abluftstromAnteilig": 0,
                "abluftstromErforderlich": 2130
            },
            {
                "code": 7,
                "abluftstutzen": false,
                "wgr": false,
                "maschinentyp": SinkType.k,
                "normtellerleistung": 1400,
                "sensibel": 3.1,
                "latent": 0.6,
                "sensibelLatent": 3.3,
                "warmeabgabe": 6.4,
                "zuluftstrom": 2390,
                "abluftstromAnteilig": 0,
                "abluftstromErforderlich": 2510
            },
            {
                "code": 8,
                "abluftstutzen": false,
                "wgr": true,
                "maschinentyp": SinkType.k,
                "normtellerleistung": 250,
                "sensibel": 0.2,
                "latent": 0.1,
                "sensibelLatent": 0.6,
                "warmeabgabe": 0.8,
                "zuluftstrom": 280,
                "abluftstromAnteilig": 0,
                "abluftstromErforderlich": 300
            },
            {
                "code": 9,
                "abluftstutzen": false,
                "wgr": true,
                "maschinentyp": SinkType.k,
                "normtellerleistung": 400,
                "sensibel": 0.5,
                "latent": 0.1,
                "sensibelLatent": 1,
                "warmeabgabe": 1.5,
                "zuluftstrom": 550,
                "abluftstromAnteilig": 0,
                "abluftstromErforderlich": 580
            },
            {
                "code": 10,
                "abluftstutzen": false,
                "wgr": true,
                "maschinentyp": SinkType.k,
                "normtellerleistung": 600,
                "sensibel": 1,
                "latent": 0.2,
                "sensibelLatent": 1.5,
                "warmeabgabe": 2.5,
                "zuluftstrom": 920,
                "abluftstromAnteilig": 0,
                "abluftstromErforderlich": 960
            },
            {
                "code": 11,
                "abluftstutzen": false,
                "wgr": true,
                "maschinentyp": SinkType.k,
                "normtellerleistung": 800,
                "sensibel": 1.4,
                "latent": 0.2,
                "sensibelLatent": 1.9,
                "warmeabgabe": 3.3,
                "zuluftstrom": 1220,
                "abluftstromAnteilig": 0,
                "abluftstromErforderlich": 1280
            },
            {
                "code": 12,
                "abluftstutzen": false,
                "wgr": true,
                "maschinentyp": SinkType.k,
                "normtellerleistung": 1000,
                "sensibel": 1.7,
                "latent": 0.3,
                "sensibelLatent": 2.4,
                "warmeabgabe": 4.1,
                "zuluftstrom": 1520,
                "abluftstromAnteilig": 0,
                "abluftstromErforderlich": 1600
            },
            {
                "code": 13,
                "abluftstutzen": false,
                "wgr": true,
                "maschinentyp": SinkType.k,
                "normtellerleistung": 1200,
                "sensibel": 1.9,
                "latent": 0.3,
                "sensibelLatent": 3,
                "warmeabgabe": 4.9,
                "zuluftstrom": 1840,
                "abluftstromAnteilig": 0,
                "abluftstromErforderlich": 1930
            },
            {
                "code": 14,
                "abluftstutzen": false,
                "wgr": true,
                "maschinentyp": SinkType.k,
                "normtellerleistung": 1400,
                "sensibel": 2.5,
                "latent": 0.3,
                "sensibelLatent": 3.3,
                "warmeabgabe": 5.8,
                "zuluftstrom": 2160,
                "abluftstromAnteilig": 0,
                "abluftstromErforderlich": 2270
            },
            {
                "code": 15,
                "abluftstutzen": false,
                "wgr": true,
                "maschinentyp": SinkType.b,
                "normtellerleistung": 1500,
                "sensibel": 4.4,
                "latent": 2.1,
                "sensibelLatent": 3.6,
                "warmeabgabe": 8,
                "zuluftstrom": 2990,
                "abluftstromAnteilig": 0,
                "abluftstromErforderlich": 3140
            },
            {
                "code": 16,
                "abluftstutzen": false,
                "wgr": true,
                "maschinentyp": SinkType.b,
                "normtellerleistung": 2000,
                "sensibel": 5.5,
                "latent": 2.7,
                "sensibelLatent": 4.8,
                "warmeabgabe": 10.3,
                "zuluftstrom": 3850,
                "abluftstromAnteilig": 0,
                "abluftstromErforderlich": 4050
            },
            {
                "code": 17,
                "abluftstutzen": false,
                "wgr": true,
                "maschinentyp": SinkType.b,
                "normtellerleistung": 2500,
                "sensibel": 6.6,
                "latent": 3.2,
                "sensibelLatent": 6,
                "warmeabgabe": 12.6,
                "zuluftstrom": 4710,
                "abluftstromAnteilig": 0,
                "abluftstromErforderlich": 4950
            },
            {
                "code": 18,
                "abluftstutzen": false,
                "wgr": true,
                "maschinentyp": SinkType.b,
                "normtellerleistung": 3000,
                "sensibel": 7.6,
                "latent": 3.8,
                "sensibelLatent": 7.2,
                "warmeabgabe": 14.8,
                "zuluftstrom": 5530,
                "abluftstromAnteilig": 0,
                "abluftstromErforderlich": 5810
            },
            {
                "code": 19,
                "abluftstutzen": false,
                "wgr": true,
                "maschinentyp": SinkType.b,
                "normtellerleistung": 4000,
                "sensibel": 9.6,
                "latent": 4.8,
                "sensibelLatent": 9.6,
                "warmeabgabe": 19.2,
                "zuluftstrom": 7170,
                "abluftstromAnteilig": 0,
                "abluftstromErforderlich": 7530
            },
            {
                "code": 20,
                "abluftstutzen": false,
                "wgr": true,
                "maschinentyp": SinkType.b,
                "normtellerleistung": 5000,
                "sensibel": 11.4,
                "latent": 5.7,
                "sensibelLatent": 12,
                "warmeabgabe": 23.4,
                "zuluftstrom": 8740,
                "abluftstromAnteilig": 0,
                "abluftstromErforderlich": 9180
            },
            {
                "code": 21,
                "abluftstutzen": true,
                "wgr": true,
                "maschinentyp": SinkType.b,
                "normtellerleistung": 1500,
                "sensibel": 4.4,
                "latent": 2.1,
                "sensibelLatent": 3.6,
                "warmeabgabe": 8,
                "zuluftstrom": 2990,
                "abluftstromAnteilig": 1000,
                "abluftstromErforderlich": 3140
            },
            {
                "code": 22,
                "abluftstutzen": true,
                "wgr": true,
                "maschinentyp": SinkType.b,
                "normtellerleistung": 2000,
                "sensibel": 5.5,
                "latent": 2.7,
                "sensibelLatent": 4.8,
                "warmeabgabe": 10.3,
                "zuluftstrom": 3850,
                "abluftstromAnteilig": 1000,
                "abluftstromErforderlich": 4050
            },
            {
                "code": 23,
                "abluftstutzen": true,
                "wgr": true,
                "maschinentyp": SinkType.b,
                "normtellerleistung": 2500,
                "sensibel": 6.6,
                "latent": 3.2,
                "sensibelLatent": 6,
                "warmeabgabe": 12.6,
                "zuluftstrom": 4710,
                "abluftstromAnteilig": 1200,
                "abluftstromErforderlich": 4950
            },
            {
                "code": 24,
                "abluftstutzen": true,
                "wgr": true,
                "maschinentyp": SinkType.b,
                "normtellerleistung": 3000,
                "sensibel": 7.6,
                "latent": 3.8,
                "sensibelLatent": 7.2,
                "warmeabgabe": 14.8,
                "zuluftstrom": 5530,
                "abluftstromAnteilig": 1200,
                "abluftstromErforderlich": 5810
            },
            {
                "code": 25,
                "abluftstutzen": true,
                "wgr": true,
                "maschinentyp": SinkType.b,
                "normtellerleistung": 4000,
                "sensibel": 9.6,
                "latent": 4.8,
                "sensibelLatent": 9.6,
                "warmeabgabe": 19.2,
                "zuluftstrom": 7170,
                "abluftstromAnteilig": 1500,
                "abluftstromErforderlich": 7530
            },
            {
                "code": 26,
                "abluftstutzen": true,
                "wgr": true,
                "maschinentyp": SinkType.b,
                "normtellerleistung": 5000,
                "sensibel": 11.4,
                "latent": 5.7,
                "sensibelLatent": 12,
                "warmeabgabe": 23.4,
                "zuluftstrom": 8740,
                "abluftstromAnteilig": 1800,
                "abluftstromErforderlich": 9180
            }
        ]
    }
}
