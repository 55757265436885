<div *ngIf="!isSectionOpen" class="project">
    <h2>Allgemeine Informationen</h2>

    <div class="generalData myData cols5">
        <mat-form-field appearance="fill" class="w2">
            <mat-label>Projekt-Name</mat-label>
            <input matInput [(ngModel)]="currentProject.projectName">
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Ort</mat-label>
            <input matInput [(ngModel)]="currentProject.city">
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Projekt-Nummer</mat-label>
            <input matInput [(ngModel)]="currentProject.projectNumber">
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Sachbearbeiter</mat-label>
            <input matInput [(ngModel)]="currentProject.clerk">
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Datum</mat-label>
            <input matInput type="date" [(ngModel)]="currentProject.date">
        </mat-form-field>


        <mat-form-field appearance="fill">
            <mat-label>Einrichtungsplan</mat-label>
            <input matInput [(ngModel)]="currentProject.plan">
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Zeichnungsnummer</mat-label>
            <input matInput [(ngModel)]="currentProject.drawingnumber">
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Stand</mat-label>
            <input matInput [(ngModel)]="currentProject.stand">
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Bemerkung</mat-label>
            <input matInput [(ngModel)]="currentProject.comment">
        </mat-form-field>

        <div class="controlGifContainer">
            <div>
                <button *ngIf="this.currentProjectID" class="control" mat-raised-button color="warn" (click)="onClickControlGif()">
                    <mat-icon>search</mat-icon> {{"label.projectsToControl" | translate}}
                </button>
            </div>
            <div class="spacer"></div>
            <mat-checkbox *ngIf="this.currentProjectID && currentProject.controlGIF" disabled="true" class="controlGIF w2" [(ngModel)]="currentProject.controlGIF">{{"label.controlGIF" | translate}}</mat-checkbox>

            <mat-form-field *ngIf="currentProject.controlGIF && admin">
                <mat-label>{{"label.gifStatus" | translate}}</mat-label>
                <mat-select [(ngModel)]="currentProject.controlGIFResult">
                    <mat-option value="open">{{"label.open"| translate}}</mat-option>
                    <mat-option value="inWork">{{"label.inWork"| translate}}</mat-option>
                    <mat-option value="done">{{"label.done"| translate}}</mat-option>
                    <mat-option value="incomplete">{{"label.incomplete"| translate}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <div *ngIf="this.currentProjectID" class="details">
        <h2>Zusammenstellung der erforderlichen Volumenströme</h2>
        <h3>
            Kochbereich
            <button mat-flat-button color="primary" (click)="onClickAddNewSection(true)">
                <mat-icon>add</mat-icon> Neuer Kochbereich hinzufügen
            </button>
        </h3>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" [class.tableWithInfo]="hasAnySectionManuellyChangedValues()">

            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef>Typ</th>
                <td mat-cell *matCellDef="let element">
                    <mat-icon>{{element.type == "kitchen"?"kitchen":"hot_tube"}}</mat-icon>
                </td>
                <td mat-footer-cell *matFooterCellDef>Summe</td>
            </ng-container>
            <ng-container matColumnDef="index">
                <th mat-header-cell *matHeaderCellDef>Nummer</th>
                <td mat-cell *matCellDef="let element; let i = index">{{i + 1}}</td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Name</th>
                <td mat-cell *matCellDef="let element">{{element.name}} {{element.hasManuellyChangedValues() ? "*" : ""}}</td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <ng-container matColumnDef="volumeAbl">
                <th mat-header-cell *matHeaderCellDef>Volumen Abluft<br>[m³/h]</th>
                <td mat-cell *matCellDef="let element">{{element.calcSectionResult().abluft | number:'1.0-0':'de-DE'}}</td>
                <td mat-footer-cell *matFooterCellDef> {{getTotalVolumeAbl(true) | number:'1.0-0':'de-DE'}} </td>
            </ng-container>
            <ng-container matColumnDef="edit">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <button mat-icon-button class="example-icon" aria-label="Example icon-button with share icon" [routerLink]="'./kitchen/' + element.ID">
                        <mat-icon>edit</mat-icon>
                    </button>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <ng-container matColumnDef="delete">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <button mat-icon-button class="example-icon" aria-label="Example icon-button with share icon" (click)="onClickDeleteSection(element)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
        </table>

        <div *ngIf="hasAnySectionManuellyChangedValues()" class="info">
            * Es wurden abweichende Werte zur Tabelle A3 der VDI 2052 verwendet
        </div>

        <h3>
            Spülbereich
            <button mat-flat-button color="primary" (click)="onClickAddNewSection(false)">
                <mat-icon>add</mat-icon> Neuer Spülbereich hinzufügen
            </button>
        </h3>
        <table mat-table [dataSource]="dataSourceSink" class="mat-elevation-z8">

            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef>Typ</th>
                <td mat-cell *matCellDef="let element">
                    <mat-icon>{{element.type == "kitchen"?"kitchen":"hot_tube"}}</mat-icon>
                </td>
                <td mat-footer-cell *matFooterCellDef>Summe</td>
            </ng-container>
            <ng-container matColumnDef="index">
                <th mat-header-cell *matHeaderCellDef>Nummer</th>
                <td mat-cell *matCellDef="let element; let i = index">{{i + 1}}</td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Name</th>
                <td mat-cell *matCellDef="let element">{{element.name}}</td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <ng-container matColumnDef="volumeAbl">
                <th mat-header-cell *matHeaderCellDef>Volumen Abluft<br>[m³/h]</th>
                <td mat-cell *matCellDef="let element">{{element.calcSectionResult().abluft | number:'1.0-0':'de-DE'}}</td>
                <td mat-footer-cell *matFooterCellDef> {{getTotalVolumeAbl(false) | number:'1.0-0':'de-DE'}} </td>
            </ng-container>
            <ng-container matColumnDef="volumeZul">
                <th mat-header-cell *matHeaderCellDef>Volume Zuluft<br>[m³/h]</th>
                <td mat-cell *matCellDef="let element">{{element.calcSectionResult().zuluft | number:'1.0-0':'de-DE'}}</td>
                <td mat-footer-cell *matFooterCellDef> {{getTotalVolumeZul(false) | number:'1.0-0':'de-DE'}} </td>
            </ng-container>
            <ng-container matColumnDef="edit">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <button mat-icon-button class="example-icon" aria-label="Example icon-button with share icon" [routerLink]="'./sink/' + element.ID">
                        <mat-icon>edit</mat-icon>
                    </button>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <ng-container matColumnDef="delete">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <button mat-icon-button class="example-icon" aria-label="Example icon-button with share icon" (click)="onClickDeleteSection(element)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumnsSink"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsSink;"></tr>
            <tr mat-footer-row *matFooterRowDef="displayedColumnsSink"></tr>
        </table>

        <h3>
            Gesamtbilanz
        </h3>
        <table mat-table class="mat-elevation-z8">

            <ng-container matColumnDef="spacer">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element"></td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <ng-container matColumnDef="volumeAbl">
                <th mat-header-cell *matHeaderCellDef>Volumen Abluft<br>[m³/h]</th>
                <td mat-cell *matCellDef="let element"></td>
                <td mat-footer-cell *matFooterCellDef> {{ getTotalVolumeAbl(false) + getTotalVolumeAbl(true) | number:'1.0-0':'de-DE' }} </td>
            </ng-container>
            <ng-container matColumnDef="volumeZul">
                <th mat-header-cell *matHeaderCellDef>Volume Zuluft<br>[m³/h]</th>
                <td mat-cell *matCellDef="let element"></td>
                <td mat-footer-cell *matFooterCellDef> {{ getTotalVolumeZul(false) + getTotalVolumeZul(true) | number:'1.0-0':'de-DE' }} </td>
            </ng-container>
            <ng-container matColumnDef="edit">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">

                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <ng-container matColumnDef="delete">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">

                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumnsResult"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsResult;"></tr>
            <tr mat-footer-row *matFooterRowDef="displayedColumnsResult"></tr>
        </table>

        <hr class="docSpacer">
        <h3>
            {{"title.documents" | translate}}
            <button [disabled]="documentService.getCount() >= 3" mat-flat-button color="primary" (click)="onClickAddNewDocument()">
                <mat-icon>add</mat-icon> {{"button.newDocument" | translate}}
            </button>
        </h3>
        <b>Premium-Variante:</b><br>
        Sie haben die Möglichkeit Ihre eigene Luftmengenberechnung durch die Spezialisten von GIF-ActiveVent kostenfrei prüfen und ggf. optimieren zu lassen. Dafür werden folgende Unterlagen von Ihnen benötigt:
        <ul>
            <li>eine Kücheneinrichtungsliste mit Anschlusswerten in kW/h</li>
            <li>Anschlussart der Küchengeräte (Elektro-, Gas- oder Dampfbeheizt)</li>
            <li>die Maße und Standorte der Küchengeräte (wand- oder freistehend) oder einen maßstabsgerechten Kücheneinrichtungsplan.</li>
        </ul>
        Sie können bis zu drei Dokumente mit jeweils max. 50 MB hochladen. Folgende Dateitypen werden unterstützt: dwg, pdf, jpg, word, excel.<br>
        Insofern alle erforderlichen Unterlagen vorhanden sind, erhalten Sie die geprüfte Luftmengenberechnung von Ihrem GIF-Betreuer per Mail innerhalb der nächsten 10 Arbeitstage.“<br>
        <br>
        <br>

        <table mat-table [dataSource]="dataSourceDocuments" class="mat-elevation-z8" [class.tableWithInfo]="hasAnySectionManuellyChangedValues()">

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>{{"label.documentName" | translate}}</th>
                <td mat-cell *matCellDef="let element">{{element.name}}</td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <ng-container matColumnDef="show">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <button mat-icon-button class="example-icon" aria-label="Example icon-button with share icon" (click)="onClickViewdocument(element)">
                        <mat-icon>open_in_new</mat-icon>
                    </button>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <ng-container matColumnDef="delete">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <button mat-icon-button class="example-icon" aria-label="Example icon-button with share icon" (click)="onClickDeleteDocument(element)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumnsDocuments"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsDocuments;"></tr>
            <tr mat-footer-row *matFooterRowDef="displayedColumnsDocuments"></tr>
        </table>
    </div>

    <div class="buttons">
        <div class="top">
            <button *ngIf="!currentProjectID" class="save" mat-raised-button color="primary" (click)="onClickNewProject()">
                <mat-icon>save</mat-icon> {{"button.newProject" | translate}}
            </button>
            <button *ngIf="currentProjectID" class="save" mat-raised-button color="primary" (click)="onClickEditProject()">
                <mat-icon>save</mat-icon> {{"button.saveProject" | translate}}
            </button>
        </div>
        <div class="bottom">
            <button *ngIf="currentProjectID" class="back" mat-raised-button color="primary" routerLink="..">
                <mat-icon>arrow_back</mat-icon> {{"button.backToProjects" | translate}}
            </button>
            <button *ngIf="currentProjectID" class="print" mat-raised-button color="primary" (click)="onClickPrint()">
                <mat-icon>print</mat-icon> {{"button.printProject" | translate}}
            </button>
            <button *ngIf="this.currentProjectID" class="control" mat-raised-button color="warn" (click)="onClickControlGif()">
                <mat-icon>search</mat-icon> {{"label.projectsToControl" | translate}}
            </button>
        </div>

    </div>
</div>


<router-outlet *ngIf="isSectionOpen"></router-outlet>