
export enum Cookies {
    LoginData = "AirFlowLoginData"
}

export class CookieService {

    static setCookie(cname: Cookies, cvalue: string, hours = 1) {
        localStorage.setItem(cname, cvalue)
    }

    static getCookie(cname: Cookies) {
        return localStorage.getItem(cname)
    }

    static resetCookie(cname: Cookies) {
        localStorage.removeItem(cname)
    }
}
