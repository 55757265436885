import { Component, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PrivatePage } from '../base/privatePage';
import { GIFControlStatus, IProject, Project } from 'src/app/models/project.model';
import { ActionType } from 'src/app/components/dialogs/dialog-message/dialog-message.component';
import { PDFService } from 'src/app/services/pdf.service';
import { IUserOverview } from 'src/app/models/user.model';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
    selector: 'app-page-projects-dashboard',
    templateUrl: './page-projects-dashboard.component.html',
    styleUrls: ['./page-projects-dashboard.component.scss']
})
export class PageProjectsDashboardComponent extends PrivatePage implements OnInit, OnDestroy {
    admin = false
    dataSource = new MatTableDataSource<IProject>([])
    displayedColumns = ["projectNumber", "projectName", "city", "date", "controlGIF", "linkFile", "print", "edit", "delete"]
    eventLogedIn
    showMyProjects = true
    forUserID: number | undefined
    otherUser: IUserOverview | undefined

    @ViewChild(MatSort) sort: MatSort | undefined;

    constructor(private injector: Injector) {
        super(injector)

        this.forUserID = this.activeRoute.snapshot.params["userID"]
        this.load(this.forUserID)
        this.eventLogedIn = this.server.eventLogin.subscribe(() => {
            this.admin = this.server.isUserAdmin()
        })
        this.admin = this.server.isUserAdmin()
    }

    ngOnInit(): void {
        this.otherUser = this.userService.getOtherUser()
    }
    ngOnDestroy(): void {
        this.eventLogedIn.unsubscribe()
    }


    onClickAddNewProject() {
        this.router.navigate(["private/projects/new"])
    }
    onClickDeleteProject(project: IProject) {
        this.openQuestionMessage("question.deleteProject").afterClosed().subscribe(async (action) => {
            if (action == ActionType.No) return

            const result = await this.server.deleteProject(project.ID)
            if (result.error) return this.openErrorMessage("error.deleteProject", result.errorMessage)

            this.dataSource.data = this.dataSource.data.filter(data => data.ID != project.ID)
            return
        })
    }

    onClickPrint(project: IProject) {
        const pdf = new PDFService(project, this.injector)
        pdf.generatePage()
    }

    onClickLink(projectData: IProject) {
        const project = new Project(projectData)

        const urlFile = document.createElement('a')
        urlFile.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(`[InternetShortcut]\nURL=${window.location.origin}/private/projects/${project.ID}`))
        urlFile.setAttribute('download', project.getFileName() + ".url")
        urlFile.click()
    }


    async load(userID?: number) {
        this.dataSource = await this.server.getProjectsAsDatasource({
            fromUser: userID
        })
        if (this.sort) {
            this.dataSource.sort = this.sort
        }
    }

    async onClickSearchProjects() {
        this.dataSource = await this.server.getProjectsAsDatasource({
            fromUser: this.showMyProjects ? undefined : -1
        })
    }

    getGIFControlStatus(project: IProject) {
        if (!project.controlGIF) return "Nein"
        if (project.controlGIFResult == GIFControlStatus.open || project.controlGIFResult == GIFControlStatus.inWork) return "Ja"

        return "Erledigt"
    }
}
