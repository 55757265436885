
import { Component, Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User, UserLogin } from 'src/app/models/user.model';
import { W3codeBase } from '../base/w3codeBase';
import { CookieService, Cookies } from 'src/app/services/cookie.service';

@Component({
    selector: 'app-page-login',
    templateUrl: './page-login.component.html',
    styleUrls: ['./page-login.component.scss']
})
export class PageLoginComponent extends W3codeBase {
    login: UserLogin = { email: "", password: "" }
    loginData?: User
    items = [
        {
            title: "Kann ich mir die Berechnungen speichern?",
            content: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet."
        }, {
            title: "Kann die Luftmenge im nachhinein noch manuell angepasst werden?",
            content: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet."
        }, {
            title: "Brauche ich einen Ansprechpartner?",
            content: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet."
        }, {
            title: "Gibt es einen Firmen-Account?",
            content: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet."
        }, {
            title: "Kann ich mir die Berechnung ausdrucken?",
            content: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet."
        }, {
            title: "Kann ich die Luftmenge in der Berechnung ändern?",
            content: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet."
        }
    ]

    constructor(private injector: Injector) {
        super(injector)

        const logedin = this.server.isUserLogedIn()
        if (logedin) {
            this.router.navigate(["private/projects"])
        }

        const logindata = CookieService.getCookie(Cookies.LoginData)
        if (logindata != "" && logindata != null) {
            this.loginData = JSON.parse(logindata)
            this.router.navigate(["private/projects"])
        }
    }


    async onClickLogin() {
        if (!this.login.email || !this.login.password) return this.openErrorMessage("error.validate.loginData")

        const loginResult = await this.server.login(this.login)
        if (loginResult.error) return this.openErrorMessage("error.login", loginResult.errorMessage)

        this.loginData = loginResult.data
        this.server.eventLogin.next(loginResult.data)
        this.router.navigate(["private/projects"])
        return
    }

    onClickRegister() {
        this.router.navigate(["register"])
    }

    async onClickResetPW() {
        if (!this.login.email) return this.openErrorMessage("error.validate.mail")

        const result = await this.server.resetPassword(this.login.email)
        if (result.error) {
            this.openErrorMessage("error.newInitPassword", result.errorMessage);
            return
        }

        this.openSuccessMessage("success.newInitPassword")
        return
    }
}
