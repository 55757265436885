<!-- <h2>{{project.projectName}} - {{project.projectNumber}}: {{section!.name}}</h2> -->

<div class="generalData myData">
    <mat-form-field appearance="fill">
        <mat-label>Spülbereich Name</mat-label>
        <input matInput [formControl]="nameFormControl">
        <mat-error *ngIf="section.name == ''">{{"error.nameMissing" | translate}}</mat-error>
    </mat-form-field>
</div>

<div class="wrapper" *ngIf="section.name != ''">
    <p>
        Die Berechnung erfolgt nach mehreren Stufen. Der höchste errechnete Wert wird in die Übersichtstabelle übernommen. Kontrollieren Sie daher alle eingegebene Daten, wenn in der Übersicht ein Wert angezeigt wird, den Sie nicht erwartet haben.
    </p>
    <div *ngIf="isMultipleSinkStepsFilled()" class="warn">
        {{ "warning.multipleSinkSteps" | translate}}
    </div>


    <mat-vertical-stepper [linear]="false" #stepper>
        <mat-step>
            <ng-template matStepLabel>Stufe 1 - Vorplanung</ng-template>
            <h2>Nomogramm Bild 4, Abschnitt 9, VDI 2052</h2>
            Abluftvolumenstrom wird nach Nomogramm Bild 4, Abschnitt 9, VDI 2052 abgeschätzt. Abluftstrom für Brat-, Grill- und Backbereich, Spülbereich wird nach Gleichung ermittelt.

            <div class="myData">
                <mat-form-field appearance="fill">
                    <mat-label>Küchengrundfläche in m²</mat-label>
                    <input matInput type="number" [(ngModel)]="section.kuechenGrundflaeche">
                </mat-form-field>

                <mat-form-field appearance="fill" [matTooltip]="'help.nomogramm' | translate">
                    <mat-label><app-text-with-help text="Kennwert laut Nomogramm m²" helpText="help.nomogramm"></app-text-with-help></mat-label>
                    <input matInput type="number" [(ngModel)]="section.kennwertNonogramm">
                </mat-form-field>

                <div>
                    <button mat-raised-button color="primary" (click)="onClickResetStep1()">
                        <mat-icon fontIcon="delete"></mat-icon>Eingaben zurücksetzen
                    </button>
                </div>


            </div>


            <div class="result">
                <h3>Raumbilanz</h3>
                <table>
                    <tr class="normal">
                        <td>
                            <span>Abluft</span>
                        </td>
                        <td>
                            {{ section.getStep1Abluft() | number:'1.0-0':'de-DE' }} m³/h
                        </td>
                    </tr>
                    <tr class="controll">
                        <td>
                            <span>Zuluft</span>
                        </td>
                        <td>
                            {{ section.getStep1Zuluft() | number:'1.0-0':'de-DE' }} m³/h
                        </td>
                    </tr>
                </table>
            </div>
        </mat-step>
        <mat-step>
            <ng-template matStepLabel>Stufe 2 - Konkretisierungsplanung</ng-template>
            <h2>Tabelle A3, Anhang A, VDI 2052, Normtellerleistung beachten</h2>

            <div class="buttons">
                <button mat-flat-button color="primary" (click)="onClickAddNewDevice()">
                    <mat-icon>add</mat-icon> Neue Spülmaschine hinzufügen
                </button>
                <button mat-flat-button color="primary" (click)="onClickOpenFile('docs/codes_sink.pdf')">
                    <mat-icon>search</mat-icon> Codes
                </button>
            </div>

            <table mat-table [dataSource]="deviceDataSource" class="mat-elevation-z8">
                <ng-container matColumnDef="code">
                    <th mat-header-cell *matHeaderCellDef>Code</th>
                    <td mat-cell *matCellDef="let element">
                        <mat-select panelClass="sinkCodeSelect" [(value)]="element.code" (selectionChange)="onChangeCode(element)">
                            <mat-option *ngFor="let code of filterdCodes" [value]="code.code">{{code.code}} - {{code.maschinentyp}} - Normteller/h: {{code.normtellerleistung | number:'1.0-0':'de-DE'}} zzgl. {{code.wgr ? "mit": "ohne"}} WRG, {{code.abluftstutzen ? "mit" : "ohne" }} Abluftstutzen</mat-option>
                        </mat-select>
                    </td>
                    <td mat-footer-cell *matFooterCellDef> </td>
                </ng-container>
                <ng-container matColumnDef="position">
                    <th mat-header-cell *matHeaderCellDef>Position</th>
                    <td mat-cell *matCellDef="let element"><input matInput [(ngModel)]="element.position" /></td>
                    <td mat-footer-cell *matFooterCellDef>Summe</td>
                </ng-container>
                <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef>Beschreibung</th>
                    <td mat-cell *matCellDef="let element"><input matInput [(ngModel)]="element.description" /></td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="maschinenType">
                    <th mat-header-cell *matHeaderCellDef>Typ</th>
                    <td mat-cell *matCellDef="let element">{{element.maschinenType}}</td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="wrg">
                    <th mat-header-cell *matHeaderCellDef matTooltip="Wärmerückgewinnung">Abluft-Wärmerück gewinnung</th>
                    <td mat-cell *matCellDef="let element">
                        <mat-icon *ngIf="element.wrg">check</mat-icon>
                        <mat-icon *ngIf="!element.wrg">close</mat-icon>
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="abluftstutzen">
                    <th mat-header-cell *matHeaderCellDef>Abluft- stutzen</th>
                    <td mat-cell *matCellDef="let element">
                        <mat-icon *ngIf="element.abluftstutzen">check</mat-icon>
                        <mat-icon *ngIf="!element.abluftstutzen">close</mat-icon>
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>


                <ng-container matColumnDef="normtellerleistung">
                    <th mat-header-cell *matHeaderCellDef>Normteller- leistung<br></th>
                    <td mat-cell *matCellDef="let element">{{element.normtellerleistung | number:'0.0':'de-DE'}}</td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="zuluftstrom">
                    <th mat-header-cell *matHeaderCellDef>Zuluftstrom<br>[m³/h]</th>
                    <td mat-cell *matCellDef="let element">{{element.zuluftstrom | number:'0.0':'de-DE'}}</td>
                    <td mat-footer-cell *matFooterCellDef>{{section.sumStep2Zuluftstrom | number:'0.0':'de-DE'}}</td>
                </ng-container>
                <ng-container matColumnDef="abluftstromAnteilig">
                    <th mat-header-cell *matHeaderCellDef>anteiliger Abluftstrom<br>[m³/h]</th>
                    <td mat-cell *matCellDef="let element">{{element.abluftstromAnteilig | number:'0.0':'de-DE'}}</td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="abluftstromErforderlich">
                    <th mat-header-cell *matHeaderCellDef>erforderlicher Abluftstrom<br>[m³/h]</th>
                    <td mat-cell *matCellDef="let element">{{element.abluftstromErforderlich | number:'0.0':'de-DE'}}</td>
                    <td mat-footer-cell *matFooterCellDef> {{section.sumStep2Abluftstrom | number:'0.0':'de-DE'}} </td>
                </ng-container>


                <ng-container matColumnDef="delete">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element">
                        <button mat-icon-button aria-label="Spülmaschine löschen" (click)="onClickDeleteSink(element)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
            </table>


            <div class="result">
                <h3>Raumbilanz</h3>
                <table>
                    <tr class="normal">
                        <td>
                            <span>Abluft</span>
                        </td>
                        <td>
                            {{ section.sumStep2Abluftstrom | number:'1.0-0':'de-DE' }} m³/h
                        </td>
                    </tr>
                    <tr class="controll">
                        <td>
                            <span>Zuluft</span>
                        </td>
                        <td>
                            {{ section.sumStep2Zuluftstrom | number:'1.0-0':'de-DE' }} m³/h
                        </td>
                    </tr>
                </table>
            </div>

        </mat-step>
        <mat-step>

            <ng-template matStepLabel>Stufe 3 - Feinplanung</ng-template>
            <h2>Ermittlung des erforderlichen Zuluftvolumenstromes und Abluftvolumenstoms</h2>
            <div class="myData cols5">
                <mat-form-field appearance="fill" [matTooltip]="'help.waermebelastungMaschine' | translate">
                    <mat-label><app-text-with-help text="Wärmebelastung der Maschine in kW" helpText="help.waermebelastungMaschine"></app-text-with-help></mat-label>
                    <input matInput type="number" [(ngModel)]="section.waermebelastungMaschine">
                </mat-form-field>

                <div class="myField">
                    <mat-form-field appearance="fill" [matTooltip]="'help.waermebelastungSpuelgut' | translate">
                        <mat-label><app-text-with-help text="Wärmebelastung des Spülguts in kW" helpText="help.waermebelastungSpuelgut"></app-text-with-help></mat-label>
                        <input matInput type="number" [(ngModel)]="section.waermebelastungSpuelgut">
                    </mat-form-field>

                    <button mat-raised-button color="primary" (click)="showCalculation = !showCalculation">Berechnung des Spülguts</button>
                    <mat-icon class="arrow" *ngIf="showCalculation">arrow_upward</mat-icon>
                </div>

                <mat-form-field appearance="fill" [matTooltip]="'help.temperatur' | translate">
                    <mat-label><app-text-with-help text="Eintrittstemperatur" helpText="help.temperatur"></app-text-with-help></mat-label>
                    <input matInput type="number" min="18" [(ngModel)]="section.eintrittsTemperatur" (change)="onChangeTemperature()">
                </mat-form-field>

                <mat-form-field appearance="fill" [matTooltip]="'help.temperatur' | translate">
                    <mat-label><app-text-with-help text="Austrittstemperatur" helpText="help.temperatur"></app-text-with-help></mat-label>
                    <input matInput type="number" [(ngModel)]="section.austrittsTemperatur" (change)="onChangeTemperature()">
                </mat-form-field>

                <div>
                    <button mat-raised-button color="primary" (click)="onClickResetStep3_2()">
                        <mat-icon fontIcon="delete"></mat-icon>Eingaben zurücksetzen
                    </button>
                </div>

            </div>

            <div class="waermebelastung" *ngIf="showCalculation">
                <h3>Die Wärmebelastung durch des Spülguts wird ermittelt:</h3>
                <p>Die Wärmebelastung des Spülguts in kW kann auch mittels Wärmeabgabe des Spülguts an den Spülraum und durch das Abkühlverhalten des Spülguts berechnet werden (lt. Tabellen 5 und 6 der VDI 2052).</p>

                <div class="myData">
                    <mat-form-field appearance="fill" [matTooltip]="'help.faktorWaermeabgabe' | translate">
                        <mat-label><app-text-with-help text="Faktor Wärmeabgabe" helpText="help.faktorWaermeabgabe"></app-text-with-help></mat-label>
                        <mat-select panelClass="selectPanelSink" [(ngModel)]="section.faktorWaermeabgabe">
                            <mat-option value="">Bitte auswählen</mat-option>
                            <mat-option value="16">16 kJ – Porzellanteller</mat-option>
                            <mat-option value="41">41 kJ – Mittlerer Casinogedecksatz</mat-option>
                            <mat-option value="65">65 kJ – Warmhalte-/Clochengedecksatz</mat-option>
                            <mat-option value="89">89 kJ – Krankenhausgedecksatz</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="fill" [matTooltip]="'help.faktorAbkuehlverhalten' | translate">
                        <mat-label><app-text-with-help text="Faktor Abkühlverhalten" helpText="help.faktorAbkuehlverhalten"></app-text-with-help></mat-label>
                        <mat-select panelClass="selectPanelSink" [(ngModel)]="section.faktorAbkuehlverhalten">
                            <mat-option value="">Bitte auswählen</mat-option>
                            <mat-option value="1">1 – Spülgut verbleibt zu 100% im Raum</mat-option>
                            <mat-option value="0.7">0,7 – Spülgut verbleibt längerer Zeit im Raum</mat-option>
                            <mat-option value="0.4">0,4 – Spülgut verbleibt in Tellerspendern o.ä.</mat-option>
                            <mat-option value="0.25">0,25 – Spülgut verlässt sofort den Raum</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="fill" [matTooltip]="'help.amoutSpuelgut' | translate">
                        <mat-label><app-text-with-help text="Menge des Spülgutes" helpText="help.amoutSpuelgut"></app-text-with-help></mat-label>
                        <input matInput type="number" (keypress)="checkIsNumber($event)" [(ngModel)]="section.amoutSpuelgut">
                    </mat-form-field>

                    <div>
                        <button mat-raised-button color="primary" (click)="onClickResetStep3()">
                            <mat-icon fontIcon="delete"></mat-icon>Eingaben zurücksetzen
                        </button>
                    </div>
                </div>
            </div>



            <div class="result">
                <h3>Raumbilanz</h3>
                <table>
                    <tr class="normal">
                        <td>
                            <span>Abluft</span>
                        </td>
                        <td>
                            {{ section.getStep3Abluft() | number:'1.0-0':'de-DE' }} m³/h
                        </td>
                    </tr>
                    <tr class="controll">
                        <td>
                            <span>Zuluft</span>
                        </td>
                        <td>
                            {{ section.getStep3Zuluft() | number:'1.0-0':'de-DE' }} m³/h
                        </td>
                    </tr>
                </table>
            </div>

        </mat-step>
    </mat-vertical-stepper>
</div>
<footer>
    <app-footer-navigation (save)="onClickSave()"></app-footer-navigation>
</footer>
