import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ServerService } from 'src/app/services/server.service';

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnDestroy {
    eventLogedIn
    admin = false

    constructor(private server: ServerService, private router: Router) {
        this.eventLogedIn = this.server.eventLogin.subscribe(() => {
            this.admin = this.server.isUserAdmin()
        })
        this.admin = this.server.isUserAdmin()
    }

    ngOnDestroy(): void {
        this.eventLogedIn.unsubscribe()
    }

    onClickLogout() {
        this.server.logout()
        this.router.navigate([""])
    }
}
