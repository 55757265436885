import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-dialog-new-version',
    templateUrl: './dialog-new-version.component.html',
    styleUrls: ['./dialog-new-version.component.scss']
})
export class DialogNewVersionComponent implements OnInit {

    constructor(public dialogRef: MatDialogRef<DialogNewVersionComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogDataNewVersion) { }

    ngOnInit(): void {
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

}


export interface DialogDataNewVersion {
    projectNumber: string;
}
