import { DataSource } from '@angular/cdk/collections';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable, ReplaySubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ISectionKitchenData, ISectionSinkData, SectionKitchenData, SectionSinkData } from './section.model';
import { IDocument } from './document.model';


export class Project implements IProject {
    ID = -1
    projectName = ""
    city = ""
    projectNumber = ""
    clerk = ""
    date: string | undefined = undefined
    plan = ""
    drawingnumber = ""
    stand = ""
    comment = ""
    userID = -1
    controlGIF = false
    controlGIFResult = GIFControlStatus.open
    kitchens: SectionKitchenData[] = []
    sinks: SectionSinkData[] = []
    documents: IDocument[] = []
    initialData: undefined | IProject

    constructor(data?: IProject) {
        if (data) {
            this.initialData = data
            const properties = Object.getOwnPropertyNames(data)
            properties.forEach(prop => {
                if (prop == "kitchens") {
                    data.kitchens.forEach((kitchenData) => {
                        const kitchen = new SectionKitchenData()
                        kitchen.loadData(kitchenData)
                        this.kitchens.push(kitchen)
                    })
                    return
                }

                if (prop == "sinks") {
                    data.sinks.forEach((sinkData) => {
                        const sink = new SectionSinkData()
                        sink.loadData(sinkData)
                        this.sinks.push(sink)
                    })
                    return
                }

                //@ts-ignore
                this[prop] = data[prop]
            })
        }
    }

    getFileName() {
        let filename = `VDI_${this.projectNumber}, ${this.city}`

        if (this.projectNumber == "" || this.projectNumber.indexOf("101-0") != 0) {
            filename = `VDI_${moment().format("YYYY-MM-DD")}_${this.projectName}_${this.city}`
        }

        return filename
    }

    saveProject() {

    }

    hasChanges() {
        if (!this.initialData) return false

        return this.hasPropertyChanged(this.initialData, this)
    }

    hasPropertyChanged(current: any, old: any) {
        console.log(current, old)
        const properties = Object.getOwnPropertyNames(old)
        for (let i = 0; i < properties.length; i++) {
            const prop = properties[i];

            if (prop == "initialData") continue
            if (prop == "codeService") continue

            console.log(prop)
            console.log(current[prop])
            console.log(old[prop])


            if (prop == "kitchens" || prop == "sinks" || prop == "documents" || prop == "devices") {
                console.log(old[prop].length, current[prop].length)
                if (old[prop].length != current[prop].length) return true

                for (let j = 0; j < old[prop].length; j++) {
                    if (this.hasPropertyChanged(current[prop][j], old[prop][j])) {
                        return true
                    }
                }
            } else {
                if (current[prop] != old[prop]) return true
            }



        }

        return false
    }
}

export interface IProject {
    ID: number
    projectName: string
    city: string
    projectNumber: string
    clerk: string
    date: string | undefined
    plan: string
    drawingnumber: string
    stand: string
    comment: string
    userID: number
    controlGIF: boolean
    controlGIFResult: GIFControlStatus
    kitchens: ISectionKitchenData[]
    sinks: ISectionSinkData[]
}




export enum GIFControlStatus {
    open = "open",
    inWork = "inWork",
    done = "done",
    incomplete = "incomplete"
}
