<!-- <h2>{{project.projectName}} - {{project.projectNumber}}: {{section!.name}}</h2> -->

<div class="generalData myData">
    <mat-form-field appearance="fill">
        <mat-label>Kochbereich Name</mat-label>
        <input matInput [formControl]="nameFormControl">
        <mat-error *ngIf="section.name == ''">{{"error.nameMissing" | translate}}</mat-error>
    </mat-form-field>
</div>


<div class="wrapper" *ngIf="section.name != ''">
    <h2>Geräteaufstellung
        <button mat-flat-button color="primary" (click)="onClickAddNewDevice()">
            <mat-icon>add</mat-icon> Neues Küchengerät oder Küchenblock hinzufügen
        </button>
        <span class="spacer"></span>
        <button mat-flat-button color="primary" (click)="onClickOpenFile('docs/codes_kitchen.pdf')">
            <mat-icon>search</mat-icon>Codes
        </button>
        <button mat-raised-button color="primary" (click)="onClickSave()">
            <mat-icon fontIcon="save"></mat-icon>Speichern
        </button>
    </h2>

    <table mat-table [dataSource]="deviceDataSource" class="mat-elevation-z8" [class.tableWithInfo]="section.hasManuellyChangedValues()">

        <ng-container matColumnDef="position">
            <th mat-header-cell *matHeaderCellDef>Position</th>
            <td mat-cell *matCellDef="let element"><input matInput [(ngModel)]="element.position" /></td>
            <td mat-footer-cell *matFooterCellDef>
                <button mat-flat-button color="primary" (click)="onClickAddNewDevice()">
                    <mat-icon>add</mat-icon>
                </button>
            </td>
        </ng-container>
        <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef>Anzahl</th>
            <td mat-cell *matCellDef="let element"><input class="right" matInput [(ngModel)]="element.amount" type="number" (ngModelChange)="element.calculateData()" /></td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef>Beschreibung</th>
            <td mat-cell *matCellDef="let element"><input matInput [(ngModel)]="element.description" /></td>
            <td mat-footer-cell *matFooterCellDef>Summe</td>
        </ng-container>
        <ng-container matColumnDef="kw">
            <th mat-header-cell *matHeaderCellDef>kW</th>
            <td mat-cell *matCellDef="let element"><input class="right" matInput [(ngModel)]="element.kw" type="number" (ngModelChange)="element.calculateData()" /></td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef>Anschlussart</th>
            <td mat-cell *matCellDef="let element">
                <mat-select [(value)]="element.type" (selectionChange)="element.calculateData()">
                    <mat-option value="EN">Elektro</mat-option>
                    <mat-option value="DN">Dampf</mat-option>
                    <mat-option value="GN">Gas</mat-option>
                </mat-select>
            </td>
            <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        <ng-container matColumnDef="code">
            <th mat-header-cell *matHeaderCellDef><app-text-with-help text="Code" helpText="help.code"></app-text-with-help></th>
            <td mat-cell *matCellDef="let element">
                <input type="text" matInput [matAutocomplete]="auto" [ngModel]="element.codeText" (keyup)="onCodeChange($event, element)" (focus)="onCodeChange($event, element)">
                <mat-autocomplete #auto="matAutocomplete" panelWidth="500px" (optionSelected)="onCodeSelected($event, element)">
                    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                        {{option!.code}} - {{option!.text}}
                    </mat-option>
                </mat-autocomplete>
                <button *ngIf="element.code" matSuffix mat-icon-button aria-label="Clear" (click)="onClickClearCode(element)">
                    <mat-icon>close</mat-icon>
                </button>
            </td>
            <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        <ng-container matColumnDef="singelSensibel">
            <th mat-header-cell *matHeaderCellDef>Qs<br>[W/kW]</th>
            <td mat-cell *matCellDef="let element">
                <input class="right" *ngIf="element.isSingleValueEditActive" matInput [(ngModel)]="element.singelSensibel" type="number" (ngModelChange)="element.calculateData()" />
                <span *ngIf="!element.isSingleValueEditActive">{{element.singelSensibel| number:'0.0':'de-DE'}}</span>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="singelDampf">
            <th mat-header-cell *matHeaderCellDef>D<br>[g/hkW]</th>
            <td mat-cell *matCellDef="let element">
                <input class="right" *ngIf="element.isSingleValueEditActive" matInput [(ngModel)]="element.singelDampf" type="number" (ngModelChange)="element.calculateData()" />
                <span *ngIf="!element.isSingleValueEditActive">{{element.singelDampf| number:'0.0':'de-DE'}}</span>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="sumSensibel">
            <th mat-header-cell *matHeaderCellDef>Q<br>[W]</th>
            <td mat-cell *matCellDef="let element">{{element.sumSensibel| number:'0.0':'de-DE'}}</td>
            <td mat-footer-cell *matFooterCellDef> {{section.sumCalcSensible | number:'0.0':'de-DE'}} </td>
        </ng-container>
        <ng-container matColumnDef="sumDampf">
            <th mat-header-cell *matHeaderCellDef>D<br>[g/h]</th>
            <td mat-cell *matCellDef="let element">{{element.sumDampf| number:'0.0':'de-DE'}}</td>
            <td mat-footer-cell *matFooterCellDef> {{section.sumCalcDampf| number:'0.0':'de-DE'}} </td>
        </ng-container>

        <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button matTooltip="Gerät löschen" aria-label="Gerät löschen" (click)="onClickDeleteDevice(element)">
                    <mat-icon>delete</mat-icon>
                </button>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="edit">
            <th mat-header-cell *matHeaderCellDef>
                <div class="outerEdit">
                    <mat-icon>edit</mat-icon><app-text-with-help helpText="help.edit"></app-text-with-help>
                </div>
            </th>
            <td mat-cell *matCellDef="let element">
                <button *ngIf="!element.isSingleValueEditActive" mat-icon-button matTooltip="Werte manuell anpassen" aria-label="Werte manuell anpassen" (click)="onClickEditSingleValues(element)">
                    <mat-icon>edit</mat-icon>
                </button>
                <button *ngIf="element.isSingleValueEditActive" mat-icon-button matTooltip="Werte zurücksetzen auf Tabelle A3 der VDI 2052" aria-label="Werte zurücksetzen" (click)="onClickEditSingleValues(element)">
                    <mat-icon>restore</mat-icon><span *ngIf="section.hasManuellyChangedValues()" class="textWarn">*</span>
                </button>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
    </table>

    <div *ngIf="section.hasManuellyChangedValues()" class="info">
        * Es wurden abweichende Werte zur Tabelle A3 der VDI 2052 verwendet
    </div>

    <h2>Berechnung des Thermikluftstromes</h2>

    <div class="additionalData myData">
        <mat-form-field appearance="fill" [matTooltip]="'help.length' | translate">
            <mat-label><app-text-with-help text="Länge in mm" helpText="help.length"></app-text-with-help></mat-label>
            <input class="withoutArrow" matInput type="number" (keypress)="checkIsNumber($event)" [(ngModel)]="section.lenght">
        </mat-form-field>

        <mat-form-field appearance="fill" [matTooltip]="'help.width' | translate">
            <mat-label><app-text-with-help text="Breite in mm" helpText="help.width"></app-text-with-help></mat-label>
            <input class="withoutArrow" matInput type="number" (keypress)="checkIsNumber($event)" [(ngModel)]="section.depth">
        </mat-form-field>

        <mat-form-field appearance="fill" [matTooltip]="'help.height' | translate">
            <mat-label><app-text-with-help text="Höhe in mm" helpText="help.height"></app-text-with-help></mat-label>
            <input class="withoutArrow" matInput type="number" (keypress)="checkIsNumber($event)" [(ngModel)]="section.height">
        </mat-form-field>

        <div class="myField" [matTooltip]="'help.z' | translate">
            <mat-label><app-text-with-help text="z in m" [number]="section.z"></app-text-with-help></mat-label><br>
            <mat-slider [displayWith]="formatSliderNumber" thumbLabel min="0.5" max="1.6" step="0.05" value="0.5" [(ngModel)]="section.z"></mat-slider>
        </div>

        <div class="myField" [matTooltip]="'help.arrangement' | translate">
            <mat-label><app-text-with-help text="Gleichzeitigkeitsfaktor" [number]="section.gleichzeitigfaktor"></app-text-with-help></mat-label><br>
            <mat-slider [displayWith]="formatSliderNumber" thumbLabel min="0.6" max="1" step="0.1" value="0.6" [(ngModel)]="section.gleichzeitigfaktor"></mat-slider>
        </div>


        <mat-form-field appearance="fill" [matTooltip]="'help.arrangement' | translate">
            <mat-label><app-text-with-help text="Anordnung" helpText="help.arrangement"></app-text-with-help></mat-label>
            <mat-select [(ngModel)]="section.anordung">
                <mat-option value="wand">Wand-stehend</mat-option>
                <mat-option value="frei">Frei-stehend</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" [matTooltip]="'help.rinsingLevel' | translate">
            <mat-label><app-text-with-help text="Ausspülgrad" helpText="help.rinsingLevel"></app-text-with-help></mat-label>
            <mat-select [(ngModel)]="section.abspuelgrad">
                <mat-option value="1.15">1,15</mat-option>
                <mat-option value="1.2">1,2</mat-option>
                <mat-option value="1.3">1,3</mat-option>
                <mat-option value="1.35">1,35</mat-option>
            </mat-select>
        </mat-form-field>

        <div>
            <button mat-raised-button color="primary" (click)="onClickReset()">
                <mat-icon fontIcon="delete"></mat-icon>Eingaben zurücksetzen
            </button>
        </div>
    </div>

    <div class="result">
        <table [class]="section.resultClass">
            <tr class="normal">
                <td>
                    <span>Berechnung des Thermikluftstroms</span>
                </td>
                <td>
                    {{section.calcAbluftNormal() | number:'1.0-0':'de-DE' }} m³/h
                </td>
            </tr>
            <tr class="controll">
                <td>
                    <span matTooltip="Kontrollrechnung, erforderlicher Abluftstrom zum Schutz vor Kondensation">Kontrollrechnung, erforderlicher Abluftstrom zum Schutz vor Kondensation</span>
                </td>
                <td>
                    {{section.calcAbluftKontrolle() | number:'1.0-0':'de-DE' }} m³/h
                </td>
            </tr>
        </table>
        Für den Bereich wird der größte Abluftstrom gewählt
    </div>
</div>
<footer>
    <app-footer-navigation (save)="onClickSave()"></app-footer-navigation>
</footer>
